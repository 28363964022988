import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import "../css/style.css";
import "../css/animate.min.css";
import "../css/responsive.css";
import "../css/bootstrap.min.css";
import check from "../images/check-icon.svg";

import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from "swiper";
import ScrollAnimation from "react-animate-on-scroll";

import PopupForm from "../components/popupForm";

import ReactHtmlParser from 'react-html-parser';

Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class Aboutus extends Component {
	constructor(props) {
		super(props);
		this.state = {
			time: 90000000,
		};
	}

	handlePopupWindow = () => {
		this.setState({ time: Math.random() });
	};

	componentDidMount() {
		var swiper = new Swiper(".partner-swiper", {
			slidesPerView: 6,
			slidesPerColumn: 1,
			spaceBetween: 40,
			speed: 300,
			autoplay: true,
			loop: true,
			observer: true,
			observeParents: true,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
			},
			breakpoints: {
	
				320: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				480: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				768: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
		
				992: {
					slidesPerView: 5,
					spaceBetween: 20,
				},
		
				1200: {
					slidesPerView: 6,
					spaceBetween: 30,
				},
			},
		});
	}
	render() {

		function  stripHtml(html) {
		
			return html.replace(/<[^>]*>/g, '');
		  }

		  const post = this.props.data.allWordpressPage.nodes[0];
		  const uniDataAll = this.props.data.allWordpressWpUniversities.nodes;
		  const featuredIds = post.acf.featured_partner_universities_section.featured_universities;

		  const uniData = featuredIds.map(id => 
			uniDataAll.find(uni => uni.wordpress_id === id)
		  ).filter(uni => uni !== undefined);

        const metatitle = post.acf.meta_title ;
        const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${this.props.path}`; 

		return (
			<div className="about-page">
				<PopupForm time={this.state.time} />
				<Layout>
					<Helmet>
				 <title>{metatitle}</title>
         		 <meta name="description" content={metadescription} />
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          
		 </Helmet>

		 <div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home </a>
											</li>
											<li class="breadcrumb-item active">{post.title}</li>
										</ol>
				</div>
				</div>
					<section class="countrie-banner-sec">
						<div class="container">
							<div class="row">
								<div class="col-12">
									<div class="countrie-banner-inner">
										
										<div class="text-box wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
											<ScrollAnimation animateIn="fadeIn" style={{ "animation-duration": "1s" }}>
												{" "}
												<h1>{post.title}</h1>
												<p>{post.acf.sub_heading}</p>{" "}
											</ScrollAnimation>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section class="uni-globally-sec">
						<ScrollAnimation animateIn="fadeIn" style={{ "animation-duration": "1s" }}>
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="uni-globally-inner">
											<h3 class="wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
											{post.acf.featured_partner_universities_section.featured_partner_universities_title}
											</h3>
											<div class="swiper-container partner-swiper wow fadeInUp" data-wow-duration="1s" data-wow-delay=".2s">
											<div className="swiper-wrapper d-flex align-items-center">
    {uniData.map((logo, index) => {

        const hasDetailPage = Array.isArray(logo.acf.university_detail_page) && logo.acf.university_detail_page.some(page => page.toLowerCase() === "yes");


  
        const websiteLink = logo.acf.website_url? logo.acf.website_url : null;

        return (
            <div key={index} className={`swiper-slide logo-position${index % 2 === 0 ? 1 : 3}`}>
                {hasDetailPage ? (
                    <a href={`/university-detail/${logo.slug}/`}>
                        <img 
                            src={logo.acf.university_logo.source_url} 
                            alt={logo.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')} 
                        />
                    </a>
                ) : websiteLink ? (
                    <a href={websiteLink} target="_blank" rel="noopener noreferrer">
                        <img 
                            src={logo.acf.university_logo.source_url} 
                            alt={logo.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')} 
                        />
                    </a>
                ) : (
                    <img 
                        src={logo.acf.university_logo.source_url} 
                        alt={logo.title.replace(/&#8211;/g, '-').replace(/&#8217;/g, '\'')} 
                    />
                )}
            </div>
        )
    })}
</div>


									
											</div>
										</div>
									</div>
								</div>
							</div>{" "}
						</ScrollAnimation>
					</section>
					<section class="academic-delivery-sec">
						<div class="container">
							<div class="row">
								<div class="col-12">
									<div class="title wow fadeInUp" data-wow-duration="1s" data-wow-delay=".3s">
										<ScrollAnimation animateIn="fadeIn" style={{ "animation-duration": "1s" }}>
											{" "}
											<h2>{post.acf.smartest_way.smartest_way_title} </h2>
											<p>{post.acf.smartest_way.smartest_way_subtext} </p>
										</ScrollAnimation>{" "}
									</div>
									<div class="academic-delivery-inner">
										<ScrollAnimation animateIn="fadeIn" style={{ "animation-duration": "1s" }}>
											{" "}
											<div class="row">
											{post.acf.smartest_way.smartest_ways.map((item, index) => (
        <div key={index} className="col-lg-4 col-md-6">
          <div className="academic-delivery-box wow fadeInUp" data-wow-duration="1s" data-wow-delay={`.${index + 4}s`}>
            <div className="image-holder">
              <img src={item.image.source_url} alt="Smartest Way" className="img-fluid" />
            </div>
            <div className="text-box">
              {ReactHtmlParser(item.content)}
            </div>
          </div>
        </div>
      ))}
											</div>{" "}
										</ScrollAnimation>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section class="online-journey-sec countrie-journey-sec best-education-sec">
						<div class="container" id="rds-yarstr-edfr">
							<div class="online-journey-inner">
								<div class="row">
									<div class="col-md-6">
										<div class="image-holder">
											<ScrollAnimation animateIn="fadeInLeft" style={{ "animation-duration": "1s" }}>
												{" "}
												<img src={post.acf.barriers.barriers_image.source_url}  alt={post.acf.barriers.barriers_title} class="img-fluid" />
											</ScrollAnimation>{" "}
										</div>
									</div>
									<div class="col-md-6">
										<div class="text-box wow fadeInRight" data-wow-duration="1s" data-wow-delay=".2s">
											<ScrollAnimation animateIn="fadeInRight" style={{ "animation-duration": "1s" }}>
												{" "}
												<h3>{post.acf.barriers.barriers_title} </h3>
												<ul>
      {post.acf.barriers.barriers_list.map((item, index) => (
        <li key={index}>
          <div className="icon-holder">
            <img src={check} alt="✓" className="img-fluid" />
          </div>
          <div className="text-inner">
            <h4>{stripHtml(item.content)}</h4>
          </div>
        </li>
      ))}
    </ul>{" "}
											</ScrollAnimation>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section class="online-journey-sec hybrid-learning-sec">
						<div class="container" id="entr-dnstr-esfrt">
							<div class="online-journey-inner">
								<div class="row">
									<div class="col-md-6">
										<div class="text-box wow fadeInRight pl-0" data-wow-duration="1s" data-wow-delay=".2s">
											<ScrollAnimation animateIn="fadeInLeft" style={{ "animation-duration": "1s" }}>
												{" "}
												<h3>{post.acf.study_overseas.study_overseas_title}</h3>
												<p>{post.acf.study_overseas.study_overseas_subtext}</p>
												<ul>
												{post.acf.study_overseas.study_overseas_ways.map((item, index) => (
        <li key={index}>
          <h4>{item.title}</h4>
          <span>{ReactHtmlParser(item.subtext)}</span>
        </li>
      ))}
												</ul>
												<a class="btn-default" onClick={() => this.handlePopupWindow()}>
												{post.acf.study_overseas.study_overseas_button}
												</a>
											</ScrollAnimation>{" "}
										</div>
									</div>
									<div class="col-md-6">
										<div class="image-holder wow fadeInLeft " data-wow-duration="1s" data-wow-delay=".1s">
											<ScrollAnimation animateIn="fadeInRight" style={{ "animation-duration": "1s" }}>
												{" "}
												<img src={post.acf.study_overseas.study_overseas_image.source_url} alt="Study Overseas" class="img-fluid ret-imft-lst-cd" />
											</ScrollAnimation>{" "}
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section class="our-team-sec">
						<ScrollAnimation animateIn="fadeIn" style={{ "animation-duration": "1s" }}>
							<div class="container">
								<div class="row">
									<div class="col-12">
										<div class="title wow fadeInUp" data-wow-duration="1s" data-wow-delay=".1s">
											<h2>{post.acf.worldgrad_team.worldgrad_team_title}</h2>
											<p></p>
										</div>
										<div class="our-team-inner">
											<div class="row">
											{post.acf.worldgrad_team.worldgrad_team_member.map((member, index) => (
        <div key={index} className="col-md-4 col-sm-6 col-6">
          <div className="our-team-box wow fadeInUp" data-wow-duration="1s" data-wow-delay={`.${index + 3}s`}>
            <div className="image-holder">
              <img src={member.image.source_url} alt={member.name} className="img-fluid" />
            </div>
            <div className="text-box">
              <h3>{member.name}</h3>
              <p>{member.designation}</p>
              <a href={member.linkedin_url} target="_blank" rel="noopener noreferrer">
                <img src="https://res.cloudinary.com/worldgrad/image/upload/v1612190386/worldgrad/linkedin-icon-blue_au09vg.svg" alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      ))}
												
												

											</div>
										</div>
									</div>
								</div>
							</div>{" "}
						</ScrollAnimation>
					</section>

					
				</Layout>
			</div>
		);
	}
}
export default Aboutus; 
export const AboutPage = graphql `
    {
		allWordpressPage(filter: {wordpress_id: {in: 4943}}) {
			nodes {
			  title
			  wordpress_id
			  acf {
				meta_title
				meta_description
				focus_keyphrase
				canonical_url
				dynamic_schema
				sub_heading
				featured_partner_universities_section {
				  featured_partner_universities_title
				  featured_universities
				}
				smartest_way {
				  smartest_way_title
				  smartest_way_subtext
				  smartest_ways {
					image {
					  source_url
					}
					content
				  }
				}
				barriers {
				  barriers_image {
					source_url
				  }
				  barriers_title
				  barriers_list {
					content
				  }
				}
				study_overseas {
				  study_overseas_title
				  study_overseas_subtext
				  study_overseas_button
				  study_overseas_url
				  study_overseas_image {
					source_url
				  }
				  study_overseas_ways {
					title
					subtext
				  }
				}
				worldgrad_team {
				  worldgrad_team_title
				  worldgrad_team_member {
					image {
					  source_url
					}
					name
					designation
					linkedin_url
				  }
				}
			  }
			}
		  }
			  allWordpressWpUniversities{
				nodes {
				  id
				  wordpress_id
				  slug
				  title
				  acf {
					country
					   website_url
          university_detail_page
		  website_url
					university_logo {
          source_url
        }
				  }
				}
			  }
}
`
